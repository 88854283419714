import React, { Component } from 'react';
import './style.scss';
import probImg from '../../assets/problem-image.png';
import solImg from '../../assets/solution-img-gray.png';

class MissionSection extends Component {
  render() {
    return (
        <div id="mission">
          <div className="section row">
            <div className="col-md-6">
              <h1 className="headder-text mission-headder">The Problem</h1>
              <p className="mission-list-item">Identifying quality emerging talent is chaotic and time consuming. Today’s industry intelligence doesn’t utilize all available data.</p>
            </div>
            <div className="col-md-6">
              <img src={probImg} className="mission-image" />
            </div>
          </div>
          <div className="gray-section row">
            <div className="col-md-6">
              <h1 className="headder-text mission-headder">The Solution</h1>
              <p className="mission-list-item">Daizic uses powerful machine learning algorithms to identify valuable emerging artists earlier than ever before. Improve your big data analysis for critical decisions.</p>
            </div>
            <div className="col-md-6">
              <img src={solImg} className="mission-image" />
            </div>
          </div>
        </div>
    );
  }
}

export default MissionSection;
