import React, { Component } from 'react';
import './style.scss';
import Testimonial from '../testimonial';
import ericImg from '../../assets/eric-berman.jpg';
import sarahImg from '../../assets/sara.jpg';
import taylorImg from '../../assets/taylor.jpg';
import steveImg from '../../assets/steve.jpeg';
import guidoImg from '../../assets/guido.jpg';
import alanImg from '../../assets/alan.png';

import disnep from '../../assets/disney.png';
import zenzi from '../../assets/zenzi_logo.png';
import btz from '../../assets/btz_Logo.png';
import ls from '../../assets/ls_logo.png';
import dl from '../../assets/dl_logo.png';

class TestimonialCarusel extends Component {
  render() {
    return (
    <div id="testemonialCarusel" className="carousel slide" data-ride="carousel" data-interval="7000">
      <ol className="carousel-indicators">
        <li className="car-control active" data-target="#testemonialCarusel" data-slide-to="0" ></li>
        <li className="car-control" data-target="#testemonialCarusel" data-slide-to="1"></li>
        <li className="car-control" data-target="#testemonialCarusel" data-slide-to="2"></li>
        <li className="car-control" data-target="#testemonialCarusel" data-slide-to="3"></li>
        <li className="car-control" data-target="#testemonialCarusel" data-slide-to="4"></li>
        <li className="car-control" data-target="#testemonialCarusel" data-slide-to="5"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <Testimonial titleImg={ls} name="Taylor Stevens" text="I need this!" title1="Director Of Business Development" title2="LiveStyle, Inc." img={taylorImg} alt="Taylor Stevens Director Of Business Development at LiveStyle, Inc testimonial" />
        </div>
        <div className="carousel-item">
          <Testimonial titleImg={"https://www.paradigmagency.com/img/logo-mark.png"} name="Alan Gary" text="The possibilities of how the music industry can benefit from what Daizic is building are endless.  AI will inevitably impact the music industry’s path and it’s clear that Daizic will be the ones to figure out how to implement it" title1="Agent" title2="Paradigm Agency" img={alanImg}  alt="Alan Gary International Agent at Paradigm Agency testimonial" />
        </div>
        <div className="carousel-item">
          <Testimonial titleImg={disnep} text="The AI that Daizic has developed is the answer to how we can get the most value from the massive datasets the music industry is producing." title1="Music Executive" title2="Walt Disney Company" img={steveImg} name="Steve Sherr" alt="Steve Sherr Music Executive at Walt Disney Company testimonial" />
        </div>
        <div className="carousel-item">
          <Testimonial titleImg={dl} name="Guido Weijerman" text="By creating a platform to help industry professionals detect emerging talent, Daizic has also delivered a champion for the artists who deserve to be discovered." title1="Former Artist Management" title2="David Lewis Productions" img={guidoImg}  alt="Guido Weijerman International Booking & Artist Management at David Lewis Productions testimonial" />
        </div>
        <div className="carousel-item">
          <Testimonial titleImg={zenzi} title1="Founder & CEO" title2="Zenzi Communications" img={sarahImg} text="Daizic is a game changer for the industry; a brilliant fusion of technology and music that will accelerate the growth of artists and labels by leaps and bounds ahead of the competition." name="Sarah Hardwick" alt="Sarah Hardwick Founder and CEO of Zenzi Communications testimonial" />
        </div>
        <div className="carousel-item">
          <Testimonial titleImg={btz} title1="CEO" title2="Partner, Brandetize" img={ericImg} text="Daizic is in line to make a massive difference in the music industry!" name="Eric Berman" alt="Eric Berman CEO and Partner, Brandetize testimonial" />
        </div>
      </div>
      <a className="carousel-control-prev car-control" href="#testemonialCarusel" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next car-control" href="#testemonialCarusel" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
    );
  }
}

export default TestimonialCarusel;
