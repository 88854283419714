
import Navbar from './../../components/navbar'
import BigImage from './../../components/bigImage'
import WelcomeSection from './../../components/welcomeSection'
import TestimonialCarusel from './../../components/TestimonialCarusel'
import CaseStudy from './../../components/CaseStudy'
import AboutSection from './../../components/aboutSection'
import MissionSection from './../../components/missionSection'
import ContactSection from './../../components/contactSection'
import Footer from '../../components/footer'
const Home = () => {
  return (
    <div>
      <Navbar/>
      <BigImage/>
      <WelcomeSection/>
      <TestimonialCarusel/>
      <CaseStudy/>
      <AboutSection/>
      <MissionSection/>
      <ContactSection/>
      <Footer/>
    </div>
  )
}

export default Home
