import React, { Component } from 'react';
import './style.scss';

class ContactSection extends Component {
  render() {
    return (
    <div className="section" id="contact">
      <h2 className="h1-responsive font-weight-bold text-center my-4 headder-text">Hit Us Up!</h2>

      <div className="row">

          <div className="col-md-12">
              <form id="contact-form" name="contact-form" action="mail.php" method="POST">

                  <div className="row">

                      <div className="col-md-6">
                          <div className="md-form mb-0">
                              <input type="text" id="name" name="name" className="form-control"/>
                              <label for="name" className="">Your name</label>
                          </div>
                      </div>

                      <div className="col-md-6">
                          <div className="md-form mb-0">
                              <input type="text" id="email" name="email" className="form-control"/>
                              <label for="email" className="">Your email</label>
                          </div>
                      </div>

                  </div>

                  <div className="row">

                      <div className="col-md-12">

                          <div className="md-form">
                              <textarea type="text" id="message" name="message" rows="3" className="form-control md-textarea"></textarea>
                              <label for="message">Your message</label>
                          </div>

                      </div>
                  </div>

              </form>

              <div className="text-center text-md-left">
                  <a className="btn btn-primary send-msg-btn" onclick="document.getElementById('contact-form').submit();">Send</a>
              </div>
              <div className="status"></div>
          </div>

      </div>
    </div>
    );
  }
}

export default ContactSection;
