import React, { Component } from 'react';
import './style.scss';
import bimg from '../../assets/betterImg.jpg';
import darkLogo from '../../assets/logo-dark.png';
import lightLogo from '../../assets/logo.png';

class BigImage extends Component {
  render() {
    return (
        <div className="bigImage-wrapper">
            <img className="bigImage" src={bimg} alt="Daizic ticketing, touring, music artifical intellegence (AI)" />
            {/*<div className="shadow-custom"></div>
            <div className="overlayText">
              {/*<img src={lightLogo} className="big-overlay-image" />}
              {<h1 className="big-overlay-text">Music Industry <br/> Intelligence</h1>
            </div>*/}
        </div>
    );
  }
}

export default BigImage;
