import React, { Component } from 'react';
import TestimonialCarusel from '../TestimonialCarusel';
import './style.scss';

class AboutSection extends Component {
  render() {
    return (
        <div className="section aboutSection" id="about">
            <div className="row">
              <div className="col-md-6">
                <h4 className="headder-text" >Daizic gives you an unfair advantage</h4>
                <p className="subheadder-text">Makes A&R more efficient and more profitable</p>
                <ul><li>Identifies emerging artists before everyone else.</li></ul>
                <p className="subheadder-text">Harnesses data to optimize tour routing</p>
                <ul><li>Pick tour locations with confidence.</li></ul>
                <p className="subheadder-text">Enhances ticketing revenue across primary and secondary markets</p>
                <ul><li>Ticket price prediction model can accurately predict sales margins.</li></ul>
                <p className="subheadder-text">Easily identify authentic fan engagement on streaming and social platforms</p>
                <ul><li>Algorithm recognizes organic growth in artists’ fan base.</li></ul>
              </div>
              <div className="col-md-6">
                <TestimonialCarusel />
              </div>
            </div>
        </div>
    );
  }
}

export default AboutSection;
