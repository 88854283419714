import React, { Component } from 'react';
import { TiNotes } from 'react-icons/ti';
import { TiTicket } from 'react-icons/ti';
import { TiPlane } from 'react-icons/ti';
import './style.scss';

class WelcomeSection extends Component {
  render() {
    return (
        <div className="section" id="about">
            <h1 className="largeAboutText"><b className="welcomeText" style={{textDecorationLine:'underline', color:'#7625ad'}}>Welcome to Daizic.</b> AI to Make the Music Industry Smarter.</h1>
            <div className="row icon-list">
              <div className="col-md-4 icon-wrapper">
                <TiNotes className="icon" />
                <h3 className="headder-text icon-text">Artist Intelligence</h3>
                <p>Make A&R more efficient and profitable. Identify emerging artists before everyone else.</p>
              </div>
              <div className="col-md-4 icon-wrapper">
                <TiPlane className="icon" />
                <h3 className="headder-text icon-text">Tour Intelligence</h3>
                <p>Pick tour locations with confidence. Harness data to optimize your tour routing.</p>
              </div>
              <div className="col-md-4 icon-wrapper">
                <TiTicket className="icon" />
                <h3 className="headder-text icon-text">Ticketing Intelligence</h3>
                <p>Enhance ticketing revenue across primary and secondary markets. Accurately predict sales margins with our advanced ticket price analytics.</p>
              </div>
            </div>
        </div>
    );
  }
}

export default WelcomeSection;
