import React, { Component } from 'react';
import './style.scss';
import logo from '../../assets/logo.png';

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };
  }

  handleScroll = () => {
    const visible = window.scrollY === 0;

    this.setState({
      visible: visible
    });

  };


  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <nav className={(this.state.visible) ? "navbar fixed-top navbar-expand-lg navbar-light bg-light navbar-top" : "navbar fixed-top navbar-expand-lg navbar-light bg-light navbar-scroll"}>
        <a className="navbar-brand" href="#">
          <img src={logo} width="30" height="30" className="d-inline-block align-top navbar-logo" alt="" />
          <span className="navbar-brand-text">DAIZIC</span>
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item" id="SXSW-btn-nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a className="nav-link"  id="SXSW-btn" target="_blank" href="https://schedule.sxsw.com/2020/events/PP1137403">Catch the Daizic Panel @ <img src={sxsw} /></a>
    </li> */}
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a className="nav-item nav-link" href="#about">Technology</a>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a className="nav-item nav-link" href="#live-detection">Live Detection</a>
            </li>
            { /*<li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a className="nav-item nav-link" onClick={this.handleClick} href="#news">News</a>
    </li> */}
            {/*<a className="nav-item nav-link" href="#mission">Our Mission</a>*/}
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a className="nav-item nav-link last" href="#footer">Contact Us</a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
