import React from 'react';
import './style.scss';
import gif from './../../assets/krc.gif'
const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <div className="not-found-left">
          <div className="not-found-heading">
            <div>Dear Daizic Users,</div>
            <div>
            We would like to inform you that we have decided to shutdown our site.

            </div>
            <div>
            Thank you.
            </div>
          

          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
