import React, { useEffect, useState } from 'react';
import './App.scss';
import NotFound from './Pages/notFound';
import { BrowserRouter, Router, Routes, Route, useNavigate } from 'react-router-dom';

import Footer from './components/footer';
import Home from './Pages/Home/home';

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === '/') {
      navigate('/shutdown')
    }
  }, [])

  return (
    <div className="App">

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shutdown" element={<NotFound />} />
      </Routes>

    </div>
  );
}

export default App;
