import React, { Component } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import './style.scss';

class Footer extends Component {
  render() {
    return (
        <div className="footer" id="footer">
          <div className="container">
        
            <div className="row">
        
              <div className="col-md-12 py-5">
                {/*<div className="mb-5 flex-center social-icons">
        
                  <a href="#" className="social-icon">
                    <FaFacebook />
                  </a>

                  <a href="#" className="social-icon">
                    <FaInstagram />
                  </a>

                  <a href="#" className="social-icon">
                    <FaLinkedin />
                  </a>

                  <a href="#" className="social-icon">
                    <FaTwitter />
                  </a>
                </div>*/}
                <p className="footer-email">Contact us at <a href="mailto:info@daizic.com">info@daizic.com</a></p>
              </div>
            </div>        
          </div>
          <div className="footer-copyright text-center py-3">© {new Date().getFullYear()} Copyright:
            <a href="#"> Daizic.com</a>
          </div>
        </div>
    );
  }
}

export default Footer;
