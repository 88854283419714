import React, { Component } from 'react';
import './style.scss';

class Testimonial extends Component {
  render() {
    console.log(this.props.alt);
    return (
      <div className="testimonial" data-ride="carousel">
        <p className="testimonialText">"{this.props.text}"</p>
        <img className="testimonialImage" src={this.props.img} alt={this.props.alt} />
        <h6 className="testimonialName">{this.props.name}</h6>
        <div className="jobTitle">
          <p className="testimonialTitle" id="firstTitle">{this.props.title1}</p>
          <img className="testimonialJobImage" src={this.props.titleImg} />
          <p className="testimonialTitle">{this.props.title2}</p>
        </div>
      </div>
    );
  }
}

export default Testimonial;
