import React, { useCallback, useEffect, useState, useRef } from 'react';
import artistImage from '../../assets/jonah_kagen.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import Chart from 'chart.js'
import moment from 'moment';
import _ from 'lodash';
import './styles.scss';
// import { metrics } from './data.json';

const metrics= [
    {
        "date": "2021-01-01T06:00:00.000Z",
        "listeners": 0,
        "plays": 0
    },
    {
        "date": "2021-01-03T06:00:00.000Z",
        "listeners": 0,
        "plays": 0
    },
    {
        "date": "2021-01-05T06:00:00.000Z",
        "listeners": 0,
        "plays": 0
    },
    {
        "date": "2021-01-07T06:00:00.000Z",
        "listeners": 0,
        "plays": 0
    },
    {
        "date": "2021-01-09T06:00:00.000Z",
        "listeners": 0,
        "plays": 0
    },
    {
        "date": "2021-01-11T06:00:00.000Z",
        "listeners": 0,
        "plays": 0
    },
    {
        "date": "2021-01-13T06:00:00.000Z",
        "listeners": 0,
        "plays": 0
    },
    {
        "date": "2021-01-15T06:00:00.000Z",
        "listeners": 0,
        "plays": 0
    },
    {
        "date": "2021-02-03T00:00:00.000Z",
        "listeners": 133095,
        "plays": 1158230
    },
    {
        "date": "2021-02-05T00:00:00.000Z",
        "listeners": 191292,
        "plays": 1172677
    },
    {
        "date": "2021-02-08T00:00:00.000Z",
        "listeners": 310334,
        "plays": 1394955
    },
    {
        "date": "2021-02-10T00:00:00.000Z",
        "listeners": 391691,
        "plays": 1422501
    },
    {
        "date": "2021-02-12T00:00:00.000Z",
        "listeners": 470096,
        "plays": 1611473
    },
    {
        "date": "2021-02-15T00:00:00.000Z",
        "listeners": 578009,
        "plays": 1638073
    },
    {
        "date": "2021-02-18T00:00:00.000Z",
        "listeners": 673829,
        "plays": 1836742
    },
    {
        "date": "2021-02-20T00:00:00.000Z",
        "listeners": 729207,
        "plays": 1869846
    },
    {
        "date": "2021-02-22T00:00:00.000Z",
        "listeners": 785027,
        "plays": 2126303
    },
    {
        "date": "2021-02-24T00:00:00.000Z",
        "listeners": 842537,
        "plays": 2415967
    },
    {
        "date": "2021-02-26T00:00:00.000Z",
        "listeners": 901004,
        "plays": 2671755
    },
    {
        "date": "2021-02-28T00:00:00.000Z",
        "listeners": 933984,
        "plays": 2787696
    },
    {
        "date": "2021-03-01T00:00:00.000Z",
        "listeners": 947841,
        "plays": 2915120
    },
    {
        "date": "2021-03-02T00:00:00.000Z",
        "listeners": 968588,
        "plays": 3221608
    },
    {
        "date": "2021-03-03T00:00:00.000Z",
        "listeners": 983243,
        "plays": 3294911
    },
    {
        "date": "2021-03-05T00:00:00.000Z",
        "listeners": 1002986,
        "plays": 3314322
    },
    {
        "date": "2021-03-11T00:00:00.000Z",
        "listeners": 1026875,
        "plays": 3857994
    },
    {
        "date": "2021-03-12T00:00:00.000Z",
        "listeners": 1030179,
        "plays": 4033622
    },
    {
        "date": "2021-03-13T00:00:00.000Z",
        "listeners": 1024885,
        "plays": 4094805
    },
    {
        "date": "2021-03-15T00:00:00.000Z",
        "listeners": 1041453,
        "plays": 4134018
    },
    {
        "date": "2021-03-16T00:00:00.000Z",
        "listeners": 1047184,
        "plays": 4162399
    },
    {
        "date": "2021-03-17T00:00:00.000Z",
        "listeners": 1048931,
        "plays": 4209240
    },
    {
        "date": "2021-03-18T00:00:00.000Z",
        "listeners": 1053556,
        "plays": 4493595
    },
    {
        "date": "2021-03-19T00:00:00.000Z",
        "listeners": 1059398,
        "plays": 4719279
    },
    {
        "date": "2021-03-21T00:00:00.000Z",
        "listeners": 1068683,
        "plays": 5526256
    },
    {
        "listeners": 1086067,
        "date": "2021-03-24T00:00:00.000Z",
        "formattedDate": "Mar 24, 2021",
        "plays": 5567148
    },
    {
        "listeners": 1090225,
        "date": "2021-03-25T00:00:00.000Z",
        "formattedDate": "Mar 25, 2021",
        "plays": 5620555
    },
    {
        "listeners": 1092473,
        "date": "2021-03-26T00:00:00.000Z",
        "formattedDate": "Mar 26, 2021",
        "plays": 5642910,
        "popularity": 71
    },
    {
        "listeners": 1091054,
        "date": "2021-03-27T00:00:00.000Z",
        "formattedDate": "Mar 27, 2021",
        "plays": 5894258
    },
    {
        "listeners": 1089360,
        "date": "2021-03-28T00:00:00.000Z",
        "formattedDate": "Mar 28, 2021",
        "plays": 5929731,
        "popularity": 71
    },
    {
        "listeners": 1090768,
        "date": "2021-04-01T00:00:00.000Z",
        "formattedDate": "Mar 30, 2021",
        "plays": 6349891,
        "popularity": 72
    },
    {
        "listeners": 1091782,
        "date": "2021-04-01T00:00:00.000Z",
        "formattedDate": "Apr 01, 2021",
        "plays": 6665619,
        "popularity": 72
    }
]
const labels = metrics.map(m => moment(m.date).utc().format('MMM YYYY'));
console.log({labels})
export const CaseStudy = () => {
    const chartRef = useRef(null);
    useEffect(() => {
        const context = chartRef.current.getContext('2d');
        new Chart(context, {
            type: 'line',

            data: {
                labels: labels,
                datasets: [{
                    label: 'Spotify Listeners',
                    data: _.map(metrics, 'listeners'),
                    fill: false,
                    borderColor: ['#a5775e'],
                    borderWidth: 4
                },
                {
                    label: 'Spotify Players',
                    data: _.map(metrics, 'plays'),
                    fill: false,
                    borderColor: ['#578c6c'],
                    borderWidth: 4
                }]
            },
            options: {
                elements: {
                    point: {
                        radius: 0
                    }
                },
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            maxTicksLimit: 3,
                            fontFamily: "Monaco, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                            fontSize: 15,
                            fontColor: 'rgba(255,255,255,0.5)',
                            maxRotation: 0,
                            minRotation: 0,
                            suggestedMax: 7000000
                        },
                        gridLines: {
                            display: false,
                            color: 'rgba(255,255,255,0.3)',
                        },
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Activity',
                            fontFamily: "Monaco, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                            fontSize: 20,
                            lineHeight: 3,
                            fontColor: 'rgba(255,255,255,0.7)'
                        },
                        gridLines: {
                            color: 'rgba(255,255,255,0.1)'
                        },
                        ticks: {
                            fontFamily: "Monaco, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                            beginAtZero: true,
                            stepSize: 500000,
                            fontSize: 15,
                            fontColor: 'rgba(255,255,255,0.7)',
                            callback: (value) => `${(_.toNumber(value) / 1000000)}`
                        }
                    }]
                }
            }
        })
    }, [chartRef])

    return (
        <div className="navbar-height" id="live-detection">
            <div className="case-study">
                <div className="row">
                    <div className="col-sm-4 artist-image">
                        <img src={artistImage} className="artist-image--img" alt="Jonah Kagen" />
                    </div>
                    <div className="col-sm-8 case-study-information-container">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="case-study-title">
                                    Daizic Live Detection
                                <FontAwesomeIcon icon={faCircle} className="case-study-title-dot" /> Jonah Kagen
                        </p>
                            </div>
                        </div>
                        <div className="row case-study-metrics-chart-container">
                            <div className="col-sm-9 case-study-metrics-chart">
                                <div className="case-study-metrics-chart-track-information track-release">
                                    <p>"Broken" <br /> released <br /> 01/28/2021</p>
                                </div>
                                <div className="case-study-metrics-chart-discovery">
                                    <p>“Broken” Detected by Daizic Feb 2, 2021 after only 13,500 plays on Spotify.</p>
                                </div>

                                <div className="case-study-metrics-chart-track-information track-hits">
                                    <p>3.290,000 plays <br /> Hit #29 on Spotify's <br />"Global Viral 50"</p>
                                </div>
                                <canvas ref={chartRef} width="100%" height="65%" />
                                <div className="case-study-metrics-chart-legends">
                                    <div className="case-study-metrics-chart-legends__label">
                                        <div className="case-study-metrics-chart-legends__label--plays" />
                                        <p>Spotify Players</p>
                                    </div>
                                    <div className="case-study-metrics-chart-legends__label">
                                        <div className="case-study-metrics-chart-legends__label--listeners" />
                                        <p>Spotify Listeners</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 case-study-story">
                                <p className="case-study-subtitle">Jonah Kagen was first discovered by "Daizic’s Fan Engagement Analytics Engine" on Feb 1st, 2021 — just 2 days after posting his first track ever — “Broken”.
                                Broken had just 13,500 plays at that time. The track has since exploded to more than 6.5 Million plays in just over 2 months.
                        </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
};

export default CaseStudy